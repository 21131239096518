







































import { Auth } from '@/services/Auth'
import { AuthResult } from '@uncharted/coverhub-framework'
import { Component, Vue } from 'vue-property-decorator'
import BackButton from '@/components/layout/BackButton.vue'
import NextButton from '@/components/layout/NextButton.vue'
import constants from '@/constants'
import { LOGIN_FAILED } from '@/constants/common'
import EmailInputRenderer from '@/components/facts/renderers/EmailInputRenderer.vue'
import { Action, Getter } from 'vuex-class'
import { ACTION_SET_EMAIL } from '@/store/modules/user/actionTypes'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { Shift } from '@/services/Shift'

@Component({
  components: {
    EmailInputRenderer,
    BackButton,
    NextButton
  }
})
export default class EmailAuth extends Vue {
  @Action(ACTION_SET_EMAIL) setEmail!: (email: string) => void
  @Getter('user/email') email!: string

  private pageValid = false
  private clicked = false

  private emailFact = {
    id: 'email',
    currentValue: ''
  }

  private emailConfig = {
    label: 'signIn.label.email',
    placeholder: 'signIn.placeHolder.email'
  }

  get valid() {
    return this.pageValid && this.emailFact.currentValue?.length > 0
  }

  get displayErrorMessage() {
    return !this.pageValid && this.emailFact.currentValue?.length > 0
  }

  created() {
    this.emailFact.currentValue = this.email
  }

  private otp() {
    this.clicked = true
    Auth.protect('guest').then(async (result: AuthResult) => {
      if (result.success) {
        // ensure user created
        const result = await Shift.ensureGuestUser(this.emailFact.currentValue)
        if (result !== 'ERROR') {
          this.login()
        } else if (result === 'ERROR') {
          await this.$router.push('/error?error=' + encodeURIComponent('guest_user_error'))
        }
      } else {
        await this.$router.push('/error?error=' + encodeURIComponent('guest_user_error'))
      }
    })
  }

  private login() {
    Auth.authenticate('otp', { identifier: this.emailFact.currentValue }).then(
      (res: AuthResult) => {
        if (res.success) {
          this.setEmail(this.emailFact.currentValue)
          this.$router.push({ name: constants.routeNames.APPLICATION_OTP })
        } else if (res.error === LOGIN_FAILED) {
          this.$showErrorPopUp(this.$t('common.error.loginFailed').toString())
        } else {
          this.$showErrorPopUp(this.$t('emailAuth.error.email').toString())
        }
        const next = this.$refs?.next as NextButton
        if (next) {
          next.reset()
        }
        this.clicked = false
      }
    )
  }

  onFactValidation(evt: FactValidationEvent) {
    this.pageValid = evt.valid
  }

  onChange(evt: FactChangeEvent) {
    this.emailFact.currentValue = evt.value?.trim()?.toLocaleLowerCase()
  }
}
